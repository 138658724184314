type FlagProps = {
  flags: any
  secondHand?: {
    value: string
    color: string
  }
}

function ProductFlag({ flags, secondHand }: FlagProps) {
  const handleFlagStyle = (): string => {
    if (flags?.offer || flags?.saleOff === 'discount') {
      return 'product-card__flag--orange'
    }

    if (flags?.differentialsTechnology === 'ECODESIGN') {
      return 'product-card__flag--green'
    }

    if (
      flags?.differentialsTechnology ||
      flags?.clusterHighlights?.[0]?.value
    ) {
      return 'product-card__flag--blue'
    }

    return ''
  }

  const flag =
    flags?.offer ||
    (flags?.saleOff === 'discount' && 'LIQUIDAÇÃO') ||
    flags?.differentialsTechnology ||
    flags?.clusterHighlights?.[0]?.value

  const flagStyle = handleFlagStyle()

  return (
    <div className="product-card__flags">
      {secondHand && secondHand?.value === 'Circula' && (
        <span className="product-card__flag bg-[#0082C3]">
          {secondHand?.value}
        </span>
      )}
      <span className={`product-card__flag ${flagStyle}`}>{flag ?? ''}</span>
    </div>
  )
}

export default ProductFlag
