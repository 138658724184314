import type { RouteComponentProps } from '@reach/router'
import { useState, useContext } from 'react'
import { AccountContext } from 'src/components/account/context'
import { ToastMessage } from 'src/components/ui/ToastMessage'
import { useLocation } from '@reach/router'

import { PersonalData } from '../../../account/Profile/PersonalData'
import { Addresses } from '../../../account/Profile/Tabs/Addresses'
import { Communication } from '../../../account/Profile/Tabs/Communication'

export interface IToastProps {
  text: string
  variant: string
  displayAlert: boolean
}

const tabs = [
  { label: 'Dados pessoais', id: 'tab1' },
  { label: 'Endereços', id: 'tab2' },
  { label: 'Comunicação', id: 'tab3' },
]

const Profile = (_: RouteComponentProps) => {
  const [toastProps, setToastProps] = useState<IToastProps>({
    text: '',
    variant: '',
    displayAlert: false,
  })

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const tabId = tabs.find((tab) => tab.id === searchParams.get('tab'))
    ? searchParams.get('tab')
    : 'tab1'

  const { userDecathlon } = useContext(AccountContext)
  const [activeTab, setActiveTab] = useState(tabId)

  return (
    <section className="md:max-w-[750px]">
      {toastProps.displayAlert && (
        <ToastMessage {...toastProps} setToastProps={setToastProps} />
      )}
      <div className="flex gap-2 overflow-x-auto max-w-[calc(100vw-2rem)] scrollbar-hide">
        {tabs.map(({ label, id }, i) => (
          <button
            key={i}
            onClick={() => setActiveTab(id)}
            className="px-6 py-3 font-semibold text-[#15181B] border border-solid border-[#C3C9CF] rounded-full aria-[current=page]:border-[#007DBC] aria-[current=page]:text-[#007DBC] whitespace-nowrap"
            aria-current={id === activeTab && 'page'}
          >
            {label}
          </button>
        ))}
      </div>

      <div>
        {activeTab === 'tab1' && userDecathlon && (
          <PersonalData
            setToastProps={setToastProps}
            userDecathlon={userDecathlon}
          />
        )}
        {activeTab === 'tab2' && <Addresses setToastProps={setToastProps} />}
        {activeTab === 'tab3' && (
          <Communication setToastProps={setToastProps} />
        )}
      </div>
    </section>
  )
}

export default Profile
