import axios from 'axios'
import type { UserDecathlon, Addresses } from 'src/components/account/types'

type GetAddressDataParams = {
  currentAddress?: Addresses
  newAddress: HTMLFormElement | null
  userDecathlon: UserDecathlon | null
}

export function getAddressData({
  currentAddress,
  newAddress,
  userDecathlon,
}: GetAddressDataParams) {
  return currentAddress
    ? {
        id: currentAddress.id,
        vtexId: currentAddress.vtexId,
        email: userDecathlon?.getUserProfile.claims.email,
        number: newAddress?.number.value,
        country_code: 'BR',
        street: newAddress?.street.value,
        locality: newAddress?.city.value,
        postal_code: newAddress?.postalcode.value,
        complement: newAddress?.complement.value,
        title: 'address',
        is_favorite: currentAddress.is_favorite,
        firstname: userDecathlon?.getUserProfile.claims.given_name,
        lastname: userDecathlon?.getUserProfile.claims.family_name,
        additional_data: {
          province: newAddress?.state.value,
          district: newAddress?.neighborhood.value,
        },
      }
    : {
        email: userDecathlon?.getUserProfile.claims.email,
        number: newAddress?.number.value,
        country_code: 'BR',
        street: newAddress?.street.value,
        locality: newAddress?.city.value,
        postal_code: newAddress?.postalcode.value,
        complement: newAddress?.complement.value,
        title: 'address',
        is_favorite: true,
        firstname: userDecathlon?.getUserProfile.claims.given_name,
        lastname: userDecathlon?.getUserProfile.claims.family_name,
        additional_data: {
          province: newAddress?.state.value,
          district: newAddress?.neighborhood.value,
        },
        type: 'SHIPPING',
      }
}

export function cepAutoComplete(
  ev: React.ChangeEvent<HTMLInputElement>,
  Ref: HTMLFormElement | null,
  setReadOnly: React.Dispatch<
    React.SetStateAction<{
      neighborhood: boolean
      street: boolean
      state: boolean
      city: boolean
    }>
  >
) {
  const { value } = ev.target
  const cep = value?.replace(/[^0-9]/g, '')

  fetch(`https://viacep.com.br/ws/${cep}/json/`)
    .then((res) => res.json())
    .then((cepData) => {
      if (cepData.erro) {
        setReadOnly({
          city: false,
          neighborhood: false,
          state: false,
          street: false,
        })

        if (Ref) {
          Ref.neighborhood.value = ''
          Ref.street.value = ''
          Ref.state.value = ''
          Ref.city.value = ''
        }

        return
      }

      if (Ref) {
        Ref.neighborhood.value = cepData.bairro
        Ref.street.value = cepData.logradouro
        Ref.state.value = cepData.uf
        Ref.city.value = cepData.localidade
      }

      setReadOnly({
        city: !!cepData.localidade,
        neighborhood: !!cepData.bairro,
        state: !!cepData.uf,
        street: !!cepData.logradouro,
      })
    })

  return null
}

type UpdateAdress = {
  action: 'update' | 'register'
  data: any
  memberAuthToken: string | null
}

export async function updateOrRegisterAddress({
  action,
  data,
  memberAuthToken,
}: UpdateAdress) {
  const endPoint = `/api/account/${
    action === 'update' ? 'updateAddress' : 'addAddress'
  }`

  try {
    await axios.post(endPoint, {
      memberAuthToken,
      data,
    })
  } catch (error) {
    console.error({ error })
  }
}

export function cepCodeMask(value: string) {
  if (!value) {
    return ''
  }

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{5})(\d)/, '$1-$2')

  return value
}

export function validadeCep(cepValue: string) {
  const cep = cepValue.replace(/\D/g, '')

  return cep.length === 8
}
