import { Link } from 'gatsby'
import type { RefObject, SetStateAction } from 'react'

type ProductCardNameProps = {
  refProd: RefObject<HTMLHeadingElement>
  showMore: boolean
  prodName: string
  linkProps: {
    to: string
    onClick: () => void
    'data-testid': string
  }
  stateShowMore: (value: SetStateAction<boolean>) => void
  buttonVisible: boolean
}

const ProductCardName = ({
  refProd,
  showMore,
  linkProps,
  prodName,
  stateShowMore,
  buttonVisible,
}: ProductCardNameProps) => {
  return (
    <div className="flex items-start flex-col relative">
      <div
        ref={refProd}
        style={{ lineHeight: '24px' }}
        className={
          showMore
            ? 'product-card__div product-card__name line-clamp-1 text-base uppercase leading-tight max-lines !min-h-[90px]'
            : 'product-card__div                    line-clamp-none text-base uppercase leading-tight max-lines !min-h-[50px]'
        }
      >
        <Link {...linkProps}>{prodName}</Link>
      </div>

      {buttonVisible && (
        <div className="relative min-w-full w-full">
          <button
            onClick={() => stateShowMore(!showMore)}
            className="absolute underline pointer"
          >
            {showMore ? 'Ver menos' : 'Ver mais'}
          </button>
        </div>
      )}
    </div>
  )
}

export default ProductCardName
