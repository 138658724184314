import { useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import type { ProductSummary_ProductFragment } from '@generated/graphql'
import { Link } from 'gatsby'
import { useUserData } from 'src/components/contexts/UserDataContext'
import { useCheckFavoriteProducts } from 'src/sdk/product/useWishlist'
import type { DataLayerProps } from 'src/utils/gtmEvents'
import {
  getObjectClickEvent,
  handleClickProductEvent,
} from 'src/utils/gtmEvents'
import { formatCategoriesForEvent } from 'src/utils/formatted'
import { Image } from 'src/components/ui/Image'

import WishlistIcon from './WishlistIcon'

type Props = {
  product: ProductSummary_ProductFragment
  linkProps: {
    to: string
    onClick: () => void
    'data-testid': string
  }
  loading: 'eager' | 'lazy'
  imageAlt?: string
  imageURL: string
  onMouseOver?: () => void
  onMouseLeave?: () => void
  children: ReactNode
  position: number
  itemId: string
}

function ProductCardSKUImage({
  product,
  linkProps,
  imageAlt,
  loading,
  children,
  position,
  itemId,
}: Props) {
  const { userData } = useUserData()
  const [imageHover, setImageHover] = useState(product?.image[0]?.url)
  const [objEvent, setObjEvent] = useState<DataLayerProps | undefined>(
    undefined
  )

  const isInWishlist = useCheckFavoriteProducts(
    userData.UserId ?? 'undefineduser',
    itemId
  )

  const correctedCategory =
    product?.customData?.categories &&
    product?.customData?.categories.length > 0
      ? formatCategoriesForEvent(product?.customData?.categories[0])
      : ''

  useEffect(() => {
    const seller = product?.customData?.items?.[0]?.sellers.find(
      (item: any) => item.sellerDefault
    )

    const obj = getObjectClickEvent({
      id: `${product?.isVariantOf?.productGroupID}`,
      name: `${product?.isVariantOf?.name}`,
      brand: `${product?.brand?.name}`,
      price: product?.offers?.lowPrice.toFixed(2),
      position: String(position + 1),
      dimension12: `${seller?.sellerId ?? ''}`,
      dimension13: `${seller?.sellerName ?? ''}`,
      arborescence: `${correctedCategory}`,
    })

    setObjEvent(obj)
  }, [product, correctedCategory, position])

  function formatSrc(url: string, width: number, height: number) {
    const hasId = url.includes('/arquivos/ids/')

    if (!hasId || !width || !height) {
      return url
    }

    const [id] = url.split('/arquivos/ids/')[1].split('/')[0].split('-')

    const formattedId = `${id}-${width}-${height}`

    return url.replace(id, formattedId)
  }

  return (
    <>
      <div className="product-card__image-container">
        <WishlistIcon
          user={userData}
          fillWishlist={isInWishlist}
          itemId={itemId}
          seller={product?.offers?.offers[0].seller?.identifier}
        />
        <Link
          className=" relative product-card__image"
          {...linkProps}
          onClick={() => {
            handleClickProductEvent(objEvent! as unknown as DataLayerProps)
          }}
        >
          <Image
            onMouseOver={() => {
              setImageHover(product?.image[1]?.url ?? product?.image[0]?.url)
            }}
            onMouseLeave={() => {
              setImageHover(product?.image[0]?.url)
            }}
            src={imageHover}
            srcSet={formatSrc(imageHover, 720, 700)}
            alt={imageAlt}
            aria-label={imageAlt}
            title={imageAlt}
            width={141}
            height={141}
            sizes="(max-width: 766px) 282px, 30vw"
            loading={loading}
            preload={loading === 'eager'}
          />
          {children}
        </Link>
      </div>
    </>
  )
}

export default ProductCardSKUImage
