import { useState, useMemo, memo, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import StarsReview from 'src/components/ui/StarsReview'
import { useProductLink } from 'src/sdk/product/useProductLink'
import type { ActiveItem } from 'src/components/ShelfLinx/ShelfBoughtTogether/types'
import Icon from 'src/components/ui/Icon'
import { getProductNameLines } from 'src/utils/formatted'
import { useGetProductsInfo } from 'src/hooks/useGetProductInfo'
import { useBFFSearch } from 'src/hooks/useBFFSearch'

import ProductFlag from './ProductFlag'
import ProductCardName from './ProductCardName'
import ProductCardSKUImage from './ProductCardSKUImage'
import ProductCardPrices from './ProductCardPrices'
import type { ProductCardProps, ProductItem } from './types'
import './product-card.scss'

type ItemsProps = {
  isAvailable: boolean | null
  itemId: string
  name: string
  color: Array<string | null> | null
  tamanho: Array<string | null> | null
  images: Array<{ imageUrl: string } | null> | null
  variations: Array<{ name: string; values: string[] }> | null
  sellers: Array<{
    sellerId: string
    sellerName: string
    commertialOffer: {
      ListPrice: number
      Price: number
      IsAvailable: boolean | null
      AvailableQuantity: number
    }
  }>
  installments: Array<{
    value: number | null
    totalValuePlusInterestRate: number | null
    numberOfInstallments: number | null
    name: string | null
  }> | null
}

function reduceColorVariant(array: ItemsProps[]) {
  array.sort((item) =>
    item?.sellers[0].commertialOffer.AvailableQuantity > 0 ? -1 : 1
  )

  return array.reduce((acc: ItemsProps[], current) => {
    const x = acc.find((item) => item.color![0] === current.color![0])

    if (!x) {
      return acc.concat([current])
    }

    return acc
  }, [])
}

function ProductCard({
  product,
  index,
  loading = 'lazy',
  handleChangedItemSKU = (_setedItem: ActiveItem) => null,
  isBoughtTogether = false,
  event,
}: ProductCardProps) {
  const {
    sku: skuMain,
    brand: { name: brandName },
    isVariantOf: { name },
    image: [img],
    offers: { lowPrice },
    customData: { flags, items },
  } = product

  const [currentSku, setCurrentSku] = useState<string>(skuMain)
  const [productItem, setProductItem] = useState<ProductItem | null>(null)
  const [image, setImage] = useState<string>(img.url)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [buttonVisible, setButtonVisible] = useState<boolean>(false)

  const {
    changeImage,
    checkProductAvailability,
    listPrice,
    spotPrice,
    inStock,
    hasDiscount,
    betterInstallment,
  } = useGetProductsInfo({
    items,
    lowPrice,
    currentSku,
    productItem,
  })

  useEffect(() => {
    const currentProductItem: ProductItem =
      items?.find((element) => element.itemId === currentSku) ??
      ({} as ProductItem)

    if (
      !currentProductItem ||
      !currentProductItem.images ||
      !currentProductItem.images[0]
    ) {
      return
    }

    checkProductAvailability({
      currentProductItem,
      isBoughtTogether,
      setCurrentSku,
      handleChangedItemSKU,
    })
    const mainImage =
      !currentProductItem.images || !currentProductItem.images[0]
        ? ''
        : currentProductItem.images[0].imageUrl

    changeImage(mainImage, setImage)
    setProductItem(currentProductItem)
  }, [
    changeImage,
    checkProductAvailability,
    currentSku,
    items,
    isBoughtTogether,
    handleChangedItemSKU,
  ])

  const linkProps = useProductLink({
    product,
    selectedOffer: 0,
    index,
    listPrice,
    event,
  })

  const colorItemsMemorized = useMemo(() => {
    if (items) {
      return reduceColorVariant(items as ItemsProps[])
    }

    return []
  }, [items])

  const fullFlags = { ...flags, saleOff: hasDiscount }
  const productDescriptionLine = useRef<HTMLHeadingElement>(null)

  const params = useMemo(() => {
    return {
      engine: 'linx',
      term: skuMain,
    }
  }, [skuMain])

  const { searchResult } = useBFFSearch(params)

  const flagCircula = searchResult?.products[0]?.flag

  useEffect(() => {
    getProductNameLines(name) && setButtonVisible(true)
  }, [name])

  return (
    <div className="relative product-card__root group bg-white">
      <ProductCardSKUImage
        position={index}
        product={product}
        itemId={skuMain}
        imageURL={image}
        linkProps={linkProps}
        loading={loading}
        imageAlt={img.alternateName}
        onMouseOver={() =>
          changeImage(
            (productItem?.images![1]?.imageUrl as string) ??
              (productItem?.images![0]?.imageUrl as string),
            setImage
          )
        }
        onMouseLeave={() =>
          changeImage(productItem?.images![0]?.imageUrl as string, setImage)
        }
      >
        {colorItemsMemorized.length > 1 && (
          <div className="product-card__colors">
            <Icon name="ColorRepresentation" height={12} width={30} />
            <span className="amount-colors">
              + {colorItemsMemorized.length - 1}
            </span>
          </div>
        )}
      </ProductCardSKUImage>

      <div className="product-card__info">
        <ProductFlag
          flags={inStock ? fullFlags : null}
          secondHand={flagCircula}
        />

        <div className="product-card__brand-image !min-h-[50px]">
          <p>{brandName.toUpperCase()}</p>
        </div>

        <div className="flex items-start flex-col relative">
          <ProductCardName
            refProd={productDescriptionLine}
            showMore={showMore}
            linkProps={linkProps}
            prodName={name}
            stateShowMore={setShowMore}
            buttonVisible={buttonVisible}
          />
        </div>

        <div className="mt-7 mb-1 h-10 flex items-center">
          <StarsReview stars={Number(flags?.rating)} />
        </div>

        <ProductCardPrices
          inStock={inStock}
          hasDiscount={hasDiscount}
          listPrice={listPrice}
          spotPrice={spotPrice}
          betterInstallment={betterInstallment}
        />
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment ProductSummary_product on StoreProduct {
    productID
    slug
    sku
    brand {
      name
    }
    name
    gtin

    isVariantOf {
      productGroupID
      name
      hasVariant {
        id: sku
      }
    }

    image {
      url
      alternateName
    }

    offers {
      lowPrice
      offers {
        availability
        price
        quantity
        seller {
          identifier
        }
      }
    }
    customData {
      categories
      flags {
        rating
        offer
        differentialsTechnology
        clusterHighlights {
          field
          value
        }
      }

      items {
        isAvailable
        itemId
        name
        images {
          imageUrl
        }
        color
        tamanho
        variations {
          name
          values
        }
        sellers {
          sellerId
          sellerName
          commertialOffer {
            ListPrice
            Price
            IsAvailable
            AvailableQuantity
          }
        }
        installments {
          value
          totalValuePlusInterestRate
          numberOfInstallments
          name
        }
      }
    }
  }
`

export default memo(ProductCard)
