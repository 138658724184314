import React, { useState, useEffect, useRef } from 'react'

import ArrowsSelectIcon from '../../../images/icons/ArrowsSelectIcon'

interface Store {
  id: string
  value: string
}

interface InputWithSuggestionsProps {
  classContainer: string
  classInput: string
  setOption: (option: Store) => void
  optionList: Store[]
  blockInput: boolean
}

const InputWithSuggestions: React.FC<InputWithSuggestionsProps> = ({
  classContainer,
  classInput,
  optionList,
  blockInput,
  setOption,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState<Store[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setShowSuggestions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setShowSuggestions(true)
    setInputValue(value)

    const filteredSuggestions = optionList.filter((option) =>
      option.value.toLowerCase().includes(value.toLowerCase())
    )

    setSuggestions(filteredSuggestions)
  }

  return (
    <div className={`flex flex-col w-full ${classContainer}`}>
      <div ref={inputRef}>
        <div className="relative">
          <div
            className={`${classInput} bg-[#fff] w-full border-neutral04 border-[1px] hover:border-[#a4adb7]  text-[#000000] select-container relative`}
          >
            <input
              type="text"
              className="w-full py-5 px-4 focus:outline-[#007dbc]"
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleInputChange}
              placeholder="Selecione"
              disabled={!blockInput}
            />
            {blockInput && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none  top-1/2 transform -translate-y-1/2">
                <ArrowsSelectIcon />
              </div>
            )}
          </div>
        </div>

        {blockInput && showSuggestions && (
          <div className="relative">
            <ul
              className="absolute bg-white border border-[#A4ADB7] shadow-md mt-2 w-full z-10"
              style={{ maxHeight: '150px', overflowY: 'auto' }}
            >
              {suggestions.map((suggestion) => (
                <button
                  type="button"
                  key={suggestion.id}
                  className="block text-left w-full p-2 cursor-pointer transition-colors duration-300 hover:bg-[#E1E4E7]"
                  onClick={() => {
                    setOption(suggestion)
                    setShowSuggestions(false)
                  }}
                >
                  {suggestion.value}
                </button>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default InputWithSuggestions
