import axios from 'axios'
import { useEffect, useState } from 'react'

type WishlistProps = {
  namelist: string
  is_public: boolean
  active: boolean
  skus: Array<{
    id: number
    quantity: number
    seller: string
  }>
}

type ToogleListProps = {
  id: string
  itemId: string
  seller: string
  e: React.MouseEvent<HTMLSpanElement, MouseEvent>
}

const getWishlistInfo = async (
  id: string,
  itemId: string,
  setState: (item: boolean) => void
): Promise<void> => {
  const { data } = await axios.get(
    `https://decathlonstore.myvtex.com/api/dataentities/LP/documents/${id}?_fields=id,lists`
  )

  const lists: WishlistProps[] = JSON.parse(data.lists)

  const wishlist = lists ?? []

  if (wishlist[0]?.skus.some((item) => item?.id?.toString() === itemId)) {
    setState(true)
  }
}

export const useCheckFavoriteProducts = (
  id: string,
  itemId: string
): boolean => {
  const [isInwishlist, setIsInWishlist] = useState(false)

  useEffect(() => {
    if (id === 'undefineduser') {
      return
    }

    getWishlistInfo(id, itemId, setIsInWishlist)
  }, [id, itemId])

  return isInwishlist
}

const updateWishlist = async (
  id: string,
  lists: WishlistProps[]
): Promise<void> => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  await axios.patch(
    'https://decathlonstore.myvtex.com/api/dataentities/LP/documents',
    {
      id,
      lists: JSON.stringify(lists),
    },
    options
  )
}

const removeFromWishlist = async (
  id: string,
  itemId: string
): Promise<void> => {
  const { data } = await axios.get(
    `https://decathlonstore.myvtex.com/api/dataentities/LP/documents/${id}?_fields=id,lists`
  )

  const lists: WishlistProps[] = JSON.parse(data.lists)

  const newList = [
    {
      namelist: lists[0]?.namelist,
      active: lists[0]?.active,
      is_public: lists[0]?.is_public,
      skus: lists[0]?.skus.filter((obj) => obj?.id?.toString() !== itemId),
    },
  ]

  updateWishlist(id, newList)
}

const addToWishlist = async (
  id: string,
  itemId: string,
  seller: string
): Promise<void> => {
  const { data } = await axios.get(
    `https://decathlonstore.myvtex.com/api/dataentities/LP/documents/${id}?_fields=id,lists`
  )

  const lists: WishlistProps[] = JSON.parse(data.lists)

  lists[0]?.skus?.push({
    id: Number(itemId),
    quantity: 1,
    seller,
  })

  updateWishlist(id, lists)
}

export const toggleWishList = ({ id, itemId, seller, e }: ToogleListProps) => {
  if (
    (e.target as HTMLSpanElement).classList.contains('favorite-button-fill')
  ) {
    ;(e.target as HTMLSpanElement).classList.remove('favorite-button-fill')
    removeFromWishlist(id, itemId)
  } else {
    ;(e.target as HTMLSpanElement).classList.add('favorite-button-fill')
    addToWishlist(id, itemId, seller)
  }
}
