import { useRef } from 'react'
import { Link, navigate } from 'gatsby'
import { CardImage } from 'src/components/account/Orders/components/CardImage'
import { CardStatus } from 'src/components/account/Orders/components/CardStatus'
import { DeliveredDate } from 'src/components/account/Orders/components/DeliveredDate'
import type { OrderItem } from 'src/components/account/Orders/types'
import { useMobile } from 'src/hooks/useMobile'

interface CardOrderProps {
  order: OrderItem
  index: number
  length: number
  page: number
}

export const CardOrder = ({ order, index, length, page }: CardOrderProps) => {
  const { isMobile } = useMobile()
  const currentPage = useRef(page)
  const orderValue = (order.value / 100).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  })

  const linkGiveBack =
    order.sellers[0].name.toLowerCase().includes('decathlonpro') ||
    order.sellers[0].name.toLowerCase().includes('iguasport ltda')
      ? 'https://decathlon.troquefacil.com.br/'
      : 'https://decathlonprodutosparceiros.troquefacil.com.br/'

  const handleClick = () => {
    if (isMobile) {
      navigate(`/account/order/${order.orderId}?page=${currentPage.current}`)
    }
  }

  return (
    <div
      className={`"w-full flex mt-[32px] ${
        index !== length ? 'mb-8 border-b' : ''
      } border-[#E1E4E7] pb-[32px] justify-between`}
    >
      <button className="w-full cursor-auto" onClick={handleClick}>
        <div className="flex sm:items-center ppp:w-full">
          <CardImage order={order} />
          <div className="ppp:w-full ppp:w-1/2 sm:ml-4 mr-[32px] text-left">
            <DeliveredDate order={order} />

            <div className="w-full mt-[54px] sm:mt-[0px] block sm:flex items-center font-inter text-[#15181B] text-[0.75rem] sm:text-[#3E4751] sm:text-[0.875rem] sm:font-medium leading-6">
              <div>
                <span>{`Pedido #${order.orderId} `}</span>
              </div>
              {!isMobile && <b className="ml-4 mr-4 text-[#687787]">•</b>}
              <div>
                <span>R$ {orderValue}</span>
              </div>

              <CardStatus
                currentStatus={order?.timeline?.currentStatus?.label}
              />
            </div>
          </div>
        </div>
      </button>
      {!isMobile && (
        <div className="flex gap-2">
          {order?.timeline?.currentStatus?.label.toLowerCase() ===
            'pedido entregue' && (
            <span className="leading-5 underline text-[#000000] text-sm font-inter font-semibold mr-[24px]">
              <a className="whitespace-nowrap" href={linkGiveBack}>
                Trocar ou devolver
              </a>
            </span>
          )}

          <Link
            to={`/account/order/${order.orderId}?page=${currentPage.current}`}
            className="leading-5 underline text-[#000000] text-sm font-inter font-semibold"
          >
            <span className="leading-5 underline text-[#000000] text-sm font-inter font-semibold">
              Detalhes
            </span>
          </Link>
        </div>
      )}
    </div>
  )
}
