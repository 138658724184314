import Icon from 'src/components/ui/Icon'
import Button from 'src/components/ui/Button'
import type { IToastProps } from 'src/components/sections/Account/Routes/Profile'

type ToastMessageProps = {
  text: string
  variant: string
  displayAlert: boolean
  setToastProps: React.Dispatch<React.SetStateAction<IToastProps>>
  closeTimeout?: number
}

export const ToastMessage = ({
  text,
  variant,
  displayAlert,
  setToastProps,
  closeTimeout = 4000,
}: ToastMessageProps) => {
  if (displayAlert) {
    setTimeout(() => {
      setToastProps({ text, variant, displayAlert: false })
    }, closeTimeout)
  }

  return (
    <div
      className={`flex items-center justify-between z-[9999] w-full h-[80px] absolute right-0 top-0 bg-white p-4 border border-solid border-[#E1E0DF] restructure-small-desktop:w-[375px] restructure-small-desktop:top-[11rem] ${variant}`}
    >
      <div className="flex items-center gap-4">
        <Icon
          name={variant === 'success' ? 'SuccessCheck' : 'ErrorWarning'}
          width={20}
          height={20}
          className="!overflow-visible"
        />
        <p
          className={`${
            variant === 'success' ? 'text-[#101010]' : 'text-[#E3262F]'
          }`}
        >
          {text}
        </p>
      </div>
      <Button
        className="flex h-[32px]"
        icon={<Icon name="CloseToast" width={32} height={32} />}
        iconPosition="right"
        onClick={() => {
          setToastProps({ text, variant, displayAlert: false })
        }}
      />
    </div>
  )
}
