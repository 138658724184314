import { Router } from '@reach/router'

import Default from './Routes/Default'
import Profile from './Routes/Profile'
import Orders from './Routes/Orders'
import Logout from './Routes/Logout'
import Wishlist from './Routes/Wishlist'
import Order from './Routes/Order'
import StoreOrders from './Routes/StoreOrders'

const Content = () => {
  return (
    <section className="flex-1 w-full md:ml-36">
      <Router basepath="/account">
        <Default path="/" />
        <Profile path="/profile" />
        <Orders path="/orders" />
        <Order path="/order/:orderId" />
        <StoreOrders path="/store-orders" />
        <StoreOrders path="/store-order/:storeOrderId" />
        <Wishlist path="/wishlist" />
        <Logout path="/logout" />
      </Router>
    </section>
  )
}

export default Content
