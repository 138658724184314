import { useCallback } from 'react'

function useConvertedImageDomain() {
  return useCallback((url: string) => {
    if (!url) {
      return ''
    }

    return url.replace(
      'https://decathlonpro.vteximg.com.br/arquivos',
      'https://decathlonpro.vtexassets.com/arquivos'
    )
  }, [])
}

export default useConvertedImageDomain
