import type { OrderItem } from 'src/components/account/Orders/types'
import { useMobile } from 'src/hooks/useMobile'

interface CardOrderProps {
  order: OrderItem
}

export const CardImage = ({ order }: CardOrderProps) => {
  const { isMobile } = useMobile()

  const urlImage = order.items
    .sort((a, b) => b.price - a.price)
    .map((item) => {
      if (isMobile) {
        return item.imageUrl.replace('80-80', '125-125')
      }

      return item.imageUrl
    })

  return (
    <div className="h-full ppp:w-full ppp:w-1/2 sm:w-auto relative">
      {order.items.length > 1 && (
        <div className="rounded-tl-md absolute top-0 left-0 bg-[#15181B] text-[#FFFFFF] w-[26px] h-[24px] text-[12px] flex items-center justify-center z-10">
          + {order.items.length - 1}
        </div>
      )}

      <div className="h-full flex items-center justify-center ppp:min-w-[146px] sm:min-w-[64px]">
        <img
          className="rounded-[4px]"
          src={urlImage[0]}
          alt="Product"
          width={isMobile ? 125 : 64}
          height={isMobile ? 125 : 64}
        />
      </div>
    </div>
  )
}
