import { useContext, useEffect, useState } from 'react'
import { AccountContext } from 'src/components/account/context'
import axios from 'axios'
import useStorage from 'src/sdk/hooks/useStorage'
import InputWithSuggestions from 'src/components/common/Input/InputWithSuggestions'

import CollectionItens from './components/CollectionItens'

interface PersonalFavoriteSportProps {
  blockInput: boolean
}

export const PersonalFavoriteSport = ({
  blockInput,
}: PersonalFavoriteSportProps) => {
  const { memberAuthToken, updateUserDecathlon } = useContext(AccountContext)
  const { storageGetItem } = useStorage()

  const [allSports, setAllSports] = useState([])
  const [sports, setSports] = useState<Array<{ id: string; value: string }>>([])

  const storageUserDecathlon = storageGetItem('user-decathlon', 'session')

  const handleSportList = async (sport: any) => {
    const sportExists = sports.some((item) => item.id === sport.id)

    if (sportExists) {
      return setSports
    }

    const response = await axios.post('/api/account/addSportPractice', {
      memberAuthToken,
      data: { sport_id: sport.id },
    })

    if (response.status === 200) {
      setSports((prevSport) => [
        ...prevSport,
        { id: sport.id, value: sport.value },
      ])

      updateUserDecathlon()
    }

    return setSports
  }

  const handleRemoveSportList = async (sport: any) => {
    const response = await axios.post('/api/account/removeSportPractice', {
      memberAuthToken,
      data: { sport_id: sport.id },
    })

    if (response.status === 200) {
      setSports((prevSports) =>
        prevSports.filter((item) => item.id !== sport.id)
      )
      updateUserDecathlon()
    }
  }

  const getSports = async () => {
    const userSportsData: any =
      JSON.parse(storageUserDecathlon).getSportsPractice

    return userSportsData
  }

  useEffect(() => {
    const fetchData = async () => {
      const [PRACTICE, response] = await Promise.all([
        await getSports(),
        await axios.get(
          `https://decathlonstore.myvtex.com/api/dataentities/SP/search?_fields=id,image,name,name_english,Sports_id,sports_practice,universo`,
          {
            headers: {
              'rest-range': 'resources=0-100',
            },
          }
        ),
      ])

      const reduced: any = []

      response.data.forEach((item: any) => {
        const duplicated =
          reduced.findIndex((redItem: any) => {
            return item.universo === redItem.universo
          }) > -1

        if (!duplicated) {
          reduced.push(item)
        }
      })

      const sportsContent = response?.data

      // const allSportsCategory = reduced?.map((sport: any) => sport.universo)
      const mainSports = sportsContent?.filter((sport: any) => {
        const mainSportsCheck = PRACTICE?.filter(
          (praticeItem: any) =>
            Number(praticeItem.sport_id) === Number(sport.Sports_id)
        )

        return Boolean(mainSportsCheck?.length)
      })

      if (mainSports) {
        mainSports.map((sport: { Sports_id: string; name: string }) => {
          const sportExists = sports.some((item) => item.id === sport.Sports_id)

          if (!sportExists) {
            setSports((prevSport) => [
              ...prevSport,
              { id: sport.Sports_id, value: sport.name },
            ])
          }

          return null
        })
      }

      const sportsInfo = response.data.map(
        (sport: { Sports_id: string; name: string }) => {
          const { name } = sport
          const storeId = sport.Sports_id

          return { id: storeId, value: name }
        }
      )

      setAllSports(
        sportsInfo.sort((a: { value: string }, b: { value: string }) =>
          a.value.localeCompare(b.value)
        )
      )
    }

    if (storageUserDecathlon) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageUserDecathlon])

  return (
    <div className="block sm:flex-col sm:flex-row py-4 w-full xs:justify-around mb-[56px] ">
      <span>Selecione seus esportes de paixão</span>

      {allSports.length > 0 && (
        <InputWithSuggestions
          classContainer="w-full"
          classInput="w-full  border-neutral04  border-[1px]  mt-2"
          setOption={handleSportList}
          optionList={allSports}
          blockInput={blockInput}
        />
      )}

      <CollectionItens
        list={sports}
        removeOption={handleRemoveSportList}
        isShowDelete
        blockInput={blockInput}
      />
    </div>
  )
}
