import { useCallback } from 'react'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import type { ProductSummary_ProductFragment } from '@generated/graphql'
import type { CurrencyCode, SelectItemEvent } from '@faststore/sdk'

import type { AnalyticsItem } from '../analytics/types'

export type ProductLinkOptions = {
  index: number
  product: ProductSummary_ProductFragment
  selectedOffer: number
  listPrice: number
  event?: string
}

export const useProductLink = ({
  index,
  product,
  selectedOffer,
  listPrice,
  event,
}: ProductLinkOptions) => {
  const { slug } = product
  const idSKU = slug.split('-').pop()
  const slug2 = slug.replace(`-${idSKU}`, '')
  const {
    currency: { code },
  } = useSession()

  const onClick = useCallback(() => {
    sendAnalyticsEvent<SelectItemEvent<AnalyticsItem>>({
      name: 'select_item',
      params: {
        items: [
          {
            item_id: product.isVariantOf.productGroupID,
            item_name: product.isVariantOf.name,
            item_brand: product.brand.name,
            item_variant: product.sku,
            index,
            price: product.offers.offers[selectedOffer].price,
            discount: listPrice - product.offers.offers[selectedOffer].price,
            currency: code as CurrencyCode,
            item_variant_name: product.name,
            product_reference_id: product.gtin,
            arborescence: '',
          },
        ],
      },
    })
  }, [code, product, index, selectedOffer, listPrice])

  let url = `/${slug2}/p`

  if (event) {
    url = `/${slug2}/p?${event}`
  }

  return {
    to: url,
    onClick,
    'data-testid': 'product-link',
  }
}
