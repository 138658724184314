interface CardStatusProps {
  currentStatus: string
}

export const CardStatus = ({ currentStatus }: CardStatusProps) => {
  const styled =
    'rounded-[4px] px-[4px] sm:ml-[16px] text-[12px] font-inter max-w-[fit-content]'

  if (currentStatus.toLowerCase() === 'cancelado') {
    return (
      <div className={`${styled} bg-[#FCE9EA] text-[#CC212A] `}>
        <span>Cancelado</span>
      </div>
    )
  }

  if (
    currentStatus.toLowerCase() === 'pedido recebido' ||
    currentStatus.toLowerCase() === 'pedido entregue'
  ) {
    return (
      <div className={`${styled} bg-[#e5f8f3] text-[#01684C] `}>
        <span>Entregue</span>
      </div>
    )
  }

  return (
    <div className={`${styled} bg-[#E6F2F8] text-[#007DBC]`}>
      <span>{currentStatus}</span>
    </div>
  )
}
