import { HAVE_WINDOW } from 'src/constants'
import { useMobile } from 'src/hooks/useMobile'
import Seo from 'src/components/seo/Seo'

import { loginUrl } from '../../../store.config'
import SideMenu from '../../components/account/SideMenu'
import Content from '../../components/sections/Account/Content'

function Account() {
  const { screenWidth } = useMobile()

  if (!HAVE_WINDOW) {
    return null
  }

  if (document.cookie.indexOf('memberAuthToken') < 0) {
    window.location.href = loginUrl

    return null
  }

  return (
    <>
      <Seo title="Minha Conta | Decathlon" />
      <div className="flex flex-col items-center justify-center py-4 mx-auto md:justify-normal md:flex-row md:items-start px-md md:py-16 max-w-7xl font-inter">
        {screenWidth >= 900 && <SideMenu />}
        <Content />
      </div>
    </>
  )
}

export default Account
