import NewCard from 'src/components/NewCard'

import Identification from './Identification'
import Cashback from './Cashback'

const AccountHome = () => {
  return (
    <div className="mb-[120px]">
      <Identification />

      <div className="flex flex-col lg:flex-row">
        <NewCard />
        <Cashback />
      </div>
    </div>
  )
}

export default AccountHome
