import { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import { AccountContext } from 'src/components/account/context'

import NewCardSkeleton from './NewCardSkeleton'
import homeIcon from './home.svg'
import mailIcon from './mail.svg'
import ridingIcon from './riding.svg'
import vectorIcon from './Vector.svg'
import homeActiveIcon from './homeActive.svg'
import mailActiveIcon from './mailActive.svg'
import ridingActiveIcon from './ridingActive.svg'
import vectorActiveIcon from './VectorActive.svg'
import redirectIcon from './redirect.svg'
import type { OrderItem, Items } from '../account/Orders/types'
import LastOrder from './LastOrder'

const PROFILE_INFO = [
  {
    text: 'Informações básicas',
    iconGray: vectorIcon,
    icon: vectorActiveIcon,
    hasInfo: true,
    link: '/account/profile',
  },
  {
    text: 'Esportes de paixão e loja',
    iconGray: ridingIcon,
    icon: ridingActiveIcon,
    hasInfo: false,
    link: '/account/profile',
  },
  {
    text: 'Meus endereços',
    iconGray: homeIcon,
    icon: homeActiveIcon,
    hasInfo: false,
    link: '/account/profile?tab=tab2',
  },
  {
    text: 'Minhas comunicações',
    iconGray: mailIcon,
    icon: mailActiveIcon,
    hasInfo: false,
    link: '/account/profile?tab=tab3',
  },
]

const NewCard = () => {
  const [profileInfo, setProfileInfo] = useState(PROFILE_INFO)

  const { userDecathlon, memberAuthToken } = useContext(AccountContext)

  const [order, setOrder] = useState<OrderItem | null>()
  const [product, setProduct] = useState<Items | null>()
  const [loader, setLoader] = useState(true)
  const [hasEmptyInfo, setHasEmptyInfo] = useState(true)

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    setProfileInfo((oldProfile) => {
      return oldProfile.map((item) => {
        if (item.text === 'Meus endereços') {
          return { ...item, hasInfo: userDecathlon?.getAddresses?.length > 0 }
        }

        if (item.text === 'Esportes de paixão e loja') {
          return {
            ...item,
            hasInfo: !!userDecathlon?.getSportsPractice,
          }
        }

        if (item.text === 'Minhas comunicações') {
          return {
            ...item,
            hasInfo:
              !!userDecathlon?.getUserProfile.claims.email &&
              !!userDecathlon?.getUserProfile.claims.phone_number,
          }
        }

        if (item.text === 'Informações básicas') {
          let hasCpf = false

          userDecathlon?.getUserProfile.additional_information.forEach(
            (info) => {
              if (info.id === 'cpf') {
                hasCpf = true
              }
            }
          )

          const verify =
            !!userDecathlon?.getUserProfile.claims.birthdate &&
            !!userDecathlon?.getUserProfile.claims.family_name &&
            !!userDecathlon?.getUserProfile.claims.gender &&
            !!userDecathlon?.getUserProfile.claims.given_name &&
            !!hasCpf

          return {
            ...item,
            hasInfo: verify,
          }
        }

        return item
      })
    })
  }, [userDecathlon])

  useEffect(() => {
    setHasEmptyInfo(profileInfo.some((info) => !info.hasInfo))
  }, [profileInfo])

  useEffect(() => {
    if (!memberAuthToken) {
      return
    }

    const getOrderList = async () => {
      try {
        const { data } = await axios.post('/api/getOrderList', {
          email: memberAuthToken,
          page: 1,
        })

        const allOrders = data.response.data.DktApp_GetOrderList.list

        if (!allOrders) {
          return
        }

        const lastOrder = getLastOrder(allOrders)

        const bestProduct = getBestProduct(lastOrder?.items)

        setOrder(lastOrder)
        setProduct(bestProduct)
      } catch (error) {
        console.error(error)
      }

      setLoader(false)
    }

    getOrderList()
  }, [memberAuthToken])

  const getLastOrder = (allOrders: OrderItem[]) => {
    if (!allOrders) {
      return null
    }

    const sortedObjects = [...allOrders].sort((a: OrderItem, b: OrderItem) => {
      const date1 = new Date(b.creationDate)
      const date2 = new Date(a.creationDate)

      return date1.valueOf() - date2.valueOf()
    })

    return sortedObjects[0]
  }

  const getBestProduct = (allProducts: Items[] | undefined) => {
    if (!allProducts) {
      return null
    }

    const sortedProducts = [...allProducts].sort(
      (a: Items, b: Items) => b.price - a.price
    )

    return sortedProducts[0]
  }

  const handleInclude = () => {
    const firstEmpty = profileInfo.find((info) => !info.hasInfo)

    if (!firstEmpty) {
      return ''
    }

    return firstEmpty.link
  }

  return loader ? (
    <div className="mt-[40px]  sm:max-w-[324px] mb-6 sm:mb-0 max-w-[348px] w-full pr-0 flex justify-center sm:justify-start">
      <NewCardSkeleton />
    </div>
  ) : (
    <div className="mt-[40px]  sm:max-w-[324px] mb-6 sm:mb-0 max-w-[348px] w-full pr-0 flex justify-center sm:justify-start">
      {!loader &&
        (!order ? (
          <div className="flex flex-col">
            <div className="h-[35px] w-full " />
            <div className="p-7 border border-[#E1E4E7] h-full flex flex-col rounded-lg sm:max-w-[281px] max-w-[348px]">
              <h4 className="font-inter text-base font-normal leading-6 tracking-normal text-left text-[#15181B]">
                Complete seu Perfil e obtenha uma experiência de compra{' '}
                <strong className="font-inter text-base font-normal text-left text-[#15181B] border-b">
                  mais personalizada
                </strong>
              </h4>
              <ul className="flex flex-col mt-[64px]">
                {profileInfo.map((info, index) => (
                  <li
                    className={`flex items-center justify-start sm:pt-[5px] sm:pb-[5px] pt-[8px] pb-[8px] cursor-default ${
                      info.hasInfo ? 'text-[#01684C]' : 'text-[#687787]'
                    } mt-[1px] mb-[1px] border-l-[6px] ${
                      info.hasInfo ? 'border-[#02BE8A]' : 'border-[#E1E4E7]'
                    }`}
                    key={index}
                  >
                    <Link className="flex" to={info.link}>
                      <div className="ml-[6px] mr-[6px]">
                        {info.hasInfo ? (
                          <img
                            className="h-[24px] w-[24px]"
                            src={info.icon}
                            alt=""
                          />
                        ) : (
                          <img
                            className="h-[24px] w-[24px]"
                            src={info.iconGray}
                            alt=""
                          />
                        )}
                      </div>

                      <p className="font-inter text-sm font-normal text-left">
                        {info.text}
                      </p>
                    </Link>
                  </li>
                ))}
              </ul>
              {hasEmptyInfo && (
                <a
                  href={handleInclude()}
                  className="mt-10 sm:mt-auto min-h-[24px] font-inter text-[16px] font-semibold text-center flex items-center justify-start gap-[8px] text-blue text-sm"
                >
                  Incluir Informações{' '}
                  <img className="" alt="" src={redirectIcon} />
                </a>
              )}
            </div>
          </div>
        ) : (
          <LastOrder order={order} product={product} />
        ))}
    </div>
  )
}

export default NewCard
