import { useState, useMemo, useCallback } from 'react'
import axios from 'axios'
import { useUserData } from 'src/components/contexts/UserDataContext'
import type { UserData } from 'src/components/contexts/UserDataContext'
import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import ProductGrid from 'src/components/product/ProductGrid'

import './wishlist.scss'

type ComponentProps = {
  classContainer: string
}

type WishlistProps = {
  active: boolean
  is_public: boolean
  namelist: string
  skus: Array<{
    id: number
    quantity: number
    seller: string
  }>
}

const Wishlist = ({ classContainer }: ComponentProps) => {
  const { userData } = useUserData()
  const [wishlist, setWishList] = useState<WishlistProps[]>([])

  const handleSetWishlist = useCallback(async (user: UserData) => {
    if (user?.UserId === 'undefineduser') {
      setWishList([])

      return
    }

    const { data } = await axios.get(
      `https://decathlonstore.myvtex.com/api/dataentities/LP/documents/${user?.UserId}?_fields=id,lists`
    )

    const wishlistObject = data.lists ? JSON.parse(data.lists) : []

    setWishList(wishlistObject)
  }, [])

  handleSetWishlist(userData)

  const productList = useProductsQuery({
    first: 12,
    after: '',
    term: `sku:${wishlist[0]?.skus?.map((item) => item.id).join(';')}`,
    selectedFacets: [],
    sort: 'score_desc',
  })

  const products = useMemo(
    () => productList?.edges?.map((edge) => edge),
    [productList]
  )

  return (
    <div className={`${classContainer} w-[90%]`}>
      <div className="wishlist-container">
        <div className="flex flex-col items-center	">
          <strong className="mt-8 text-xl italic">FAVORITOS</strong>
          <span className="text-sm">
            PRODUTOS NA SUA LISTA ({products?.length})
          </span>
        </div>
        {wishlist.length === 0 ? (
          <div className="text-center	p-4 bg-gray text-sm my-8 mb-8	">
            Não foram encontrados produtos nesta lista.
          </div>
        ) : (
          products && <ProductGrid products={products} page={1} pageSize={50} />
        )}
      </div>
    </div>
  )
}

export { Wishlist }

export default Wishlist
