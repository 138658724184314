import Skeleton from 'react-loading-skeleton'
import Section from 'src/components/sections/Section'
import { ITEMS_PER_PAGE } from 'src/constants'
import type { PropsWithChildren } from 'react'
import { useMobile } from 'src/hooks/useMobile'

import 'src/components/product/ProductGrid/product-grid.scss'
import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  loading?: boolean
}

function ProductGridSkeleton({
  children,
  loading = true,
}: PropsWithChildren<Props>) {
  const { screenWidth } = useMobile()

  return loading ? (
    <Section>
      <ul className="flex flex-wrap m-0 gap-2">
        {Array.from({ length: ITEMS_PER_PAGE / 2 }, (__, idx) => (
          <li key={String(idx)} className="w-full md:w-[calc(100%/3-8px)]">
            <Skeleton height={screenWidth < 768 ? 370 : 580} />
          </li>
        ))}
      </ul>
    </Section>
  ) : (
    <>{children}</>
  )
}

export default ProductGridSkeleton
