import { navigate } from 'gatsby'
import type { UserData } from 'src/components/contexts/UserDataContext'
import { toggleWishList } from 'src/sdk/product/useWishlist'

import { loginUrl } from '../../../../store.config'

type WishlistIconProps = {
  user: UserData
  fillWishlist: boolean
  itemId: string
  seller: string
}

const WishlistIcon = ({
  user,
  fillWishlist,
  itemId,
  seller,
}: WishlistIconProps) => {
  return (
    <span
      className={`wishlist-ico absolute top-2 right-2 z-30 favorite-button hidden w-[20px] h-[20px] self-end my-auto ml-auto cursor-pointer ${
        fillWishlist ? 'favorite-button-fill' : ''
      }`}
      onClick={(e) =>
        user.UserId
          ? toggleWishList({
              id: user.UserId,
              itemId,
              seller,
              e,
            })
          : navigate(loginUrl)
      }
      aria-hidden="true"
    />
  )
}

export default WishlistIcon
