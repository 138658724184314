import { useState, useCallback, useEffect, useMemo } from 'react'
import axios from 'axios'
import qs from 'query-string'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'
import type { Product } from 'src/components/product/types'

import * as storeConfig from '../../store.config'

const { decathlonSearch } = storeConfig

type ParamsProps = {
  engine: string
  term: string | null
  sc?: number
  page?: number
  resultsPerPage?: number
  sort?: string
  filter?: string[]
  deviceId?: string
}

type ProductsPerPage = {
  page: number
  products: Product[]
}

export const useBFFSearch = (parameters: ParamsProps) => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null)
  const [productsByPage, setProductsByPage] = useState<ProductsPerPage[]>([])

  const resultError = useMemo(() => {
    return {
      facets: [],
      filteredRecords: 0,
      pagination: { next: 0, last: 0 },
      products: [],
      sort: [],
    }
  }, [])

  const fetchData = useCallback(async () => {
    const url = `${decathlonSearch}/product/search`

    try {
      const { data } = await axios.get(`${url}?${qs.stringify(parameters)}`)

      setSearchResult(data)

      if (parameters?.page && parameters?.resultsPerPage) {
        const products = {
          page: parameters?.page,
          products: data.products,
          last: data.pagination.last,
        }

        setProductsByPage((prevState) => prevState.concat(products))
      }
    } catch (error) {
      setSearchResult(resultError)
    }
  }, [resultError, parameters])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { searchResult, productsByPage }
}
