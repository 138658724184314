import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'

import ProductCardInstallments from './ProductCardInstallments'
import type { Installment } from './types'

type Props = {
  inStock: boolean
  hasDiscount: 'discount' | 'no-discount'
  spotPrice: number
  listPrice: number
  betterInstallment: Installment | null
}

function ProductCardPrices({
  inStock,
  hasDiscount,
  spotPrice,
  listPrice,
  betterInstallment,
}: Props) {
  const oldPriceFormatted = useFormattedPrice(listPrice)
  const spotPriceFormatted = useFormattedPrice(spotPrice)
  const [spotPriceFirst, spotPriceCents] = spotPriceFormatted.split(',')

  return (
    <>
      <div className="product-card__old-price">
        {inStock && hasDiscount === 'discount' && (
          <p className="swiper-no-swiping">
            antes <span>{oldPriceFormatted}</span>{' '}
          </p>
        )}
      </div>

      {inStock ? (
        <>
          <div
            className="product-card__prices"
            data-testid="price"
            data-value={spotPrice}
          >
            <div className={`product-card__prices-main ${hasDiscount}`}>
              <span className="swiper-no-swiping">{spotPriceFirst}</span>
              {','}
              <span className="swiper-no-swiping cents">{spotPriceCents}</span>
            </div>
          </div>

          <ProductCardInstallments installment={betterInstallment} />
        </>
      ) : (
        <div className="product-card__no-stock">
          <div className="no-stock__title">Produto indisponível</div>
          <div className="no-stock__description">Avise-me quando chegar</div>
        </div>
      )}
    </>
  )
}

export default ProductCardPrices
