import { useContext, useState } from 'react'
import { EllipsisIcon } from 'src/components/Icons/EllipsisIcon'
import { useMobile } from 'src/hooks/useMobile'
import { AccountContext } from 'src/components/account/context'
import axios from 'axios'
import type { IToastProps } from 'src/components/sections/Account/Routes/Profile'

import DeleteConfirmation from './DeleteConfirmation'
import AddressControlsMobile from './AddressControlsMobile'

type AddressControlsProps = {
  currentAddress: string
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<string>>
  setToastProps: React.Dispatch<React.SetStateAction<IToastProps>>
}

const AddressControls = ({
  setIsModalOpen,
  setSelectedAddress,
  currentAddress,
  setToastProps,
}: AddressControlsProps) => {
  const { memberAuthToken, updateUserDecathlon } = useContext(AccountContext)
  const { isMobile } = useMobile()

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showControlsMobile, setShowControlsMobile] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function handleEdit() {
    setSelectedAddress(currentAddress)
    setIsModalOpen(true)
    setShowControlsMobile(false)
  }

  async function handleDelete() {
    try {
      setIsLoading(true)
      await axios.post('/api/account/removeAddress', {
        memberAuthToken,
        addressId: currentAddress,
      })

      await updateUserDecathlon()
      setIsLoading(false)
      setToastProps({
        text: 'Endereço excluido',
        variant: 'success',
        displayAlert: true,
      })
    } catch (err) {
      console.error(err)
      setIsLoading(false)
      setToastProps({
        text: 'Erro ao excluir os dados, tente novamente mais tarde',
        variant: 'error',
        displayAlert: true,
      })
    }
  }

  return (
    <div>
      {isMobile ? (
        <div>
          <button onClick={() => setShowControlsMobile(true)}>
            <EllipsisIcon />
          </button>

          {showControlsMobile && !showDeleteConfirmation && (
            <AddressControlsMobile
              handleEdit={() => handleEdit()}
              setShowControlsMobile={setShowControlsMobile}
              setShowDeleteConfirmation={setShowDeleteConfirmation}
            />
          )}
        </div>
      ) : (
        <div className="controls-container flex gap-8 self-start relative">
          <button
            aria-label="Editar"
            className="font-inter text-sm font-medium text-blue"
            onClick={() => handleEdit()}
          >
            Editar
          </button>
          <button
            aria-label="Apagar"
            className="font-inter text-sm font-medium text-restructure-error"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Apagar
          </button>
        </div>
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          setShowDeleteConfirmation={setShowDeleteConfirmation}
          handleDelete={() => handleDelete()}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default AddressControls
