import { useContext, useState } from 'react'
import type { Addresses } from 'src/components/account/types'
import { AccountContext } from 'src/components/account/context'
import type { IToastProps } from 'src/components/sections/Account/Routes/Profile'
import LoadingSVG from 'src/components/sections/Account/Loaders/LoadingSVG'

import AddressControls from './AddressControls'
import { updateOrRegisterAddress } from './utils/addressUtils'

type AddressDataProps = {
  address: Addresses
  setIsAddressFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<string>>
  setToastProps: React.Dispatch<React.SetStateAction<IToastProps>>
}

const AddressCard = ({
  address,
  setIsAddressFormOpen,
  setSelectedAddress,
  setToastProps,
}: AddressDataProps) => {
  const { userDecathlon, memberAuthToken, updateUserDecathlon } =
    useContext(AccountContext)

  const [isLoading, setIsLoading] = useState(false)

  const {
    street,
    locality,
    complement,
    postal_code: postalCode,
    is_favorite: isFavorite,
    id,
    vtexId,
    title,
    administrative_divisions: { province, district },
  } = address

  async function handleChange() {
    try {
      setIsLoading(true)
      await updateOrRegisterAddress({
        action: 'update',
        data: {
          id,
          vtexId,
          email: userDecathlon?.getUserProfile.claims.email,
          number: street?.split(',')?.[1]?.trim(),
          country_code: 'BR',
          street: street?.split(',')?.[0]?.trim(),
          locality,
          postal_code: postalCode,
          complement,
          title,
          is_favorite: true,
          firstname: userDecathlon?.getUserProfile.claims.given_name,
          lastname: userDecathlon?.getUserProfile.claims.family_name,
          additional_data: {
            province,
            district,
          },
        },
        memberAuthToken,
      })

      await updateUserDecathlon()
      setToastProps({
        text: 'Endereço salvo',
        variant: 'success',
        displayAlert: true,
      })

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setToastProps({
        text: 'Erro ao salvar os dados, tente novamente mais tarde',
        variant: 'error',
        displayAlert: true,
      })
      console.error(error)
    }
  }

  return (
    <li className="flex flex-col font-inter border-solid border-b border-neutral04 last:border-b-0 pb-8 mb-8">
      <div className="data-and-controls flex justify-between">
        <div className="address-data-container">
          <div className="font-semibold text-sm">
            {street} - {locality} ({province})
          </div>
          <small className="text-xs font-normal text-darkGray">
            {complement && `${complement} | `} {postalCode}
          </small>
        </div>

        <AddressControls
          setIsModalOpen={setIsAddressFormOpen}
          setSelectedAddress={setSelectedAddress}
          currentAddress={id}
          setToastProps={setToastProps}
        />
      </div>

      <div className="main-address-input text-sm font-normal flex relative gap-1.5 mt-4 px-7 ">
        <label
          className="flex cursor-pointer items-center justify-center gap-2"
          htmlFor={`address-${id}`}
        >
          Endereço principal{' '}
          {isLoading && (
            <LoadingSVG style={{ width: 'fit-content', margin: '0' }} />
          )}
          {''}
          <input
            className="hidden"
            type="radio"
            id={`address-${id}`}
            name="main-address"
            checked={isFavorite}
            onChange={() => handleChange()}
          />
          <span
            id="checkmark"
            className="absolute t0 left-0 w-[20px] h-[20px] rounded-full border-solid border-[2.5px] border-neutral-400"
          />
        </label>
      </div>
    </li>
  )
}

export default AddressCard
