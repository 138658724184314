import CloseListIcon from '../../../../../images/icons/CloseListIcon'

interface Item {
  id: string
  value: string
}

interface CollectionItensProps {
  list: any
  isShowDelete: boolean
  removeOption: (option: Item) => void
  blockInput: boolean
}

const CollectionItens: React.FC<CollectionItensProps> = ({
  list,
  isShowDelete,
  removeOption,
  blockInput,
}) => {
  return list.length > 0
    ? list.map((item: any) => (
        <div
          key={item.id}
          className={`w-full connection-mobile border-[#C3C9CF] border-[1px] mt-5 p-2 focus:outline-none hover:border-[#d4d7d9] flex items-center justify-between py-5 px-4 ${
            blockInput ? 'bg-[#ffffff]' : 'bg-[#FAFAFA]'
          }`}
        >
          {item.value}

          {isShowDelete && (
            <button
              type="button"
              className="ml-auto cursor-pointer"
              onClick={() => {
                removeOption(item)
              }}
              disabled={!blockInput}
            >
              <CloseListIcon />
            </button>
          )}
        </div>
      ))
    : null
}

export default CollectionItens
