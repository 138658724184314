import axios from 'axios'
import { useEffect, useContext, useState } from 'react'
import type { RouteComponentProps } from '@reach/router'
import { AccountContext } from 'src/components/account/context'
import OnlineStoreOrders from 'src/components/account/Orders/components/OnlineStoreOrders'
import PhysicalStoreOrders from 'src/components/account/Orders/components/PhysicalStoreOrders'
import type { OnlineStoreOrderType } from 'src/components/account/Orders/types'
import OrderTabs from 'src/components/account/Orders/components/OrderTabs'
import { NoOrdersSkeleton } from 'src/components/account/Orders/components/NoOrdersSkeleton'

import { NoOrders } from '../../../account/Orders/components/NoOrders'

export type StoreType = 'physical' | 'online'

const RenderOrders = ({
  store,
  ListOrders,
  page,
  setValuePage,
}: {
  store: StoreType
  ListOrders: OnlineStoreOrderType
  page: number
  setValuePage: React.Dispatch<React.SetStateAction<number>>
}) => {
  if (store === 'physical') {
    return <PhysicalStoreOrders />
  }

  return (
    <OnlineStoreOrders
      ListOrders={ListOrders}
      setPage={setValuePage}
      page={page}
    />
  )
}

const Orders = (_: RouteComponentProps) => {
  const { memberAuthToken } = useContext(AccountContext)
  const [DataOrders, setDataorders] = useState<OnlineStoreOrderType | null>(
    null
  )

  const [store, setStore] = useState<StoreType>('online')
  const [loader, setLoader] = useState(true)

  const [currentPage, setcurrentPage] = useState(1)

  useEffect(() => {
    if (!memberAuthToken) {
      return
    }

    const getOrderList = async () => {
      if (store !== 'online') {
        return
      }

      const endPoint = '/api/getOrderList'

      try {
        const { data } = await axios.post(endPoint, {
          email: memberAuthToken,
          page: currentPage,
        })

        const { list } = data.response.data.DktApp_GetOrderList

        setDataorders((oldData) => {
          return oldData
            ? { ...oldData, list: [...oldData.list, ...list] }
            : data.response.data.DktApp_GetOrderList
        })

        setLoader(false)
      } catch (error) {
        console.error(error)
      }
    }

    getOrderList()
  }, [memberAuthToken, store, currentPage])

  return (
    <section className="w-full">
      <OrderTabs store={store} setStore={setStore} />

      {loader && !DataOrders ? (
        <NoOrdersSkeleton />
      ) : !DataOrders?.list.length ? (
        <NoOrders />
      ) : (
        <RenderOrders
          store={store}
          ListOrders={DataOrders}
          setValuePage={setcurrentPage}
          page={currentPage}
        />
      )}
    </section>
  )
}

export default Orders
