import { BagCartOutline } from 'src/components/Icons/BagCartOutline'
import { Circle } from 'src/components/Icons/Circle'

export const NoOrders = () => {
  return (
    <div className="mt-[54px] sm:border sm:border-[#E1E4E7] sm:rounded-md w-full sm:h-32 p-8 flex justify-center items-center mb-[10.5rem] sm:mb-[13.1rem]">
      <div className="flex gap-4 flex-col items-center sm:flex-row sm:items-start">
        <div className="relative">
          <Circle
            color="#E1E4E7"
            className="absolute inset-0 w-full h-full rounded-full z-0"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <BagCartOutline
              color="#007DBC"
              className="restructure-small-desktop:w-lg restructure-small-desktop:h-lg"
            />
          </div>
        </div>
        <div className="flex flex-col w-full items-center sm:items-start">
          <span className="font-inter font-medium text-sm text-[#15181B] mb-4 no-underline mt-0 sm:mb-2">
            Ainda sem nenhuma compra
          </span>
          <div className="flex flex-col items-center sm:items-start">
            <span className="text-tertiary font-inter text-[#687787] text-xs font-normal leading-4 no-underline text-center">
              Pronto para escolher seu próximo esporte?
            </span>
            <span className="text-tertiary font-inter text-[#687787] text-xs font-normal leading-4 no-underline text-center">
              Explore nossos produtos e aproveite o cashback!
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
