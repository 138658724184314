import ProductGridSkeleton from 'src/components/skeletons/ProductGridSkeleton'

import ProductCard from '../ProductCard'

import './product-grid.scss'

interface Props {
  products: any
  page: number
  pageSize: number
}

function ProductGrid({ products, page, pageSize }: Props) {
  return (
    <ProductGridSkeleton loading={products.length === 0}>
      <ul className="product-grid">
        {products.map(({ node: product }: any, idx: number) => (
          <li key={`${product.productID}`}>
            <ProductCard
              key={`${idx}_${product.productID}`}
              product={product}
              index={pageSize * page + idx + 1}
              loading={idx < 2 ? 'eager' : 'lazy'}
            />
          </li>
        ))}
      </ul>
    </ProductGridSkeleton>
  )
}

export default ProductGrid
