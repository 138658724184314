import { useEffect } from 'react'
import type { RouteComponentProps } from '@reach/router'
import Seo from 'src/components/seo/Seo'

function Logout(_: RouteComponentProps) {
  useEffect(() => {
    sessionStorage.removeItem('user-decathlon')
    window.location.href = 'https://secure.decathlon.com.br/member/logout'
  }, [])

  return <Seo />
}

export default Logout
