import { useState } from 'react'
import { useAccountContext } from 'src/components/account/context'
import Section from 'src/components/sections/Section'
import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import './styles.scss'
import axios from 'axios'
import type { IToastProps } from 'src/components/sections/Account/Routes/Profile'

interface IHandleCheckbox {
  memberAuthToken: string | null
  updateUserDecathlon: () => Promise<void>
  setToastProps: React.Dispatch<React.SetStateAction<IToastProps>>
  value: boolean
}

type ToggleSwitchProps = {
  label: string
  isDisabled: boolean
  userOptIn?: boolean
  setUserOptIn: React.Dispatch<React.SetStateAction<boolean>>
}

type CommunicationProps = {
  setToastProps: React.Dispatch<React.SetStateAction<IToastProps>>
}

export const ToggleSwitch = ({
  label,
  isDisabled,
  userOptIn,
  setUserOptIn,
}: ToggleSwitchProps) => {
  return (
    <div className="flex items-center w-100 border border-solid border-[#E1E4E7] pt-4 pb-2 px-4">
      <label className="relative inline-block w-[58px] h-[34px] min-w-[58px]">
        <input
          className="opacity-0 w-0 h-0"
          type="checkbox"
          disabled={isDisabled}
          defaultChecked={userOptIn}
          onChange={(e) => {
            setUserOptIn(e.target.checked)
          }}
        />
        <span className="slider round" />
      </label>
      <p className="ml-4 text-sm">{label}</p>
    </div>
  )
}

const handleCheckbox = async ({
  memberAuthToken,
  updateUserDecathlon,
  setToastProps,
  value = false,
}: IHandleCheckbox) => {
  const data = {
    purposes: [
      {
        id: 'mysports-RmzeVX4K',
        enabled: value,
      },
    ],
  }

  await axios
    .post('/api/account/updateConsent', {
      memberAuthToken,
      data,
    })
    .then(
      ({
        data: {
          updateConsent: { status },
        },
      }) => {
        const text =
          status === 'confirmed'
            ? 'Alterações salvas'
            : 'Erro ao salvar os dados, tente novamente mais tarde'

        const variant = status === 'confirmed' ? 'success' : 'error'

        setToastProps({
          text,
          variant,
          displayAlert: true,
        })
      }
    )

  await updateUserDecathlon()
}

export const Communication = ({ setToastProps }: CommunicationProps) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const buttonText = isDisabled ? 'Editar preferências' : 'Salvar alterações'

  const { userDecathlon, memberAuthToken, updateUserDecathlon } =
    useAccountContext()

  const [userOptIn, setUserOptIn] = useState<boolean>(
    userDecathlon?.getConsent?.events[0]?.consents?.purposes?.filter(
      (e) => e.id === 'mysports-RmzeVX4K'
    )?.[0]?.enabled
  )

  return (
    <Section className="mb-[6.5rem] mt-14 text-[#15181B] restructure-small-desktop:mb-14">
      <div className="border-b border-solid border-[#E1E4E7] py-3">
        <Button
          className="flex items-center gap-2 text-sm font-semibold text-[#007DBC]"
          icon={
            <Icon
              name={isDisabled ? 'EditPreferences' : 'SavePreferences'}
              width={16}
              height={16}
            />
          }
          iconPosition="right"
          onClick={() => {
            !isDisabled &&
              handleCheckbox({
                memberAuthToken,
                updateUserDecathlon,
                setToastProps,
                value: userOptIn,
              })
            setIsDisabled(!isDisabled)
          }}
        >
          {buttonText}
        </Button>
      </div>
      <div className="mt-8">
        <p className="leading-6 mb-4">Preferências</p>
        {/* TODO: Implement all toggles when we have the updated API configuration
        <ToggleSwitch
          label="Atualizações do seu pedido"
          isDisabled={isDisabled}
        /> */}
        <ToggleSwitch
          label="Quero receber saldo de cashback, descontos exclusivos, novidades e informações de eventos."
          isDisabled={isDisabled}
          userOptIn={userOptIn}
          setUserOptIn={setUserOptIn}
        />
      </div>
      {/* TODO: Implement all toggles when we have the updated API configuration
      <div className="mt-10">
        <p className="leading-6 mb-4">Canais ativos</p>
        <ToggleSwitch label="SMS" isDisabled={isDisabled} />
        <ToggleSwitch label="Whatsapp" isDisabled={isDisabled} />
        <ToggleSwitch label="E-mail" isDisabled={isDisabled} />
      </div> */}
    </Section>
  )
}
