import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import jwt_decode from 'jwt-decode'
import { getCookie } from 'src/utils/getCookie'
import useStorage from 'src/sdk/hooks/useStorage'
import ArrowLink from 'src/images/icons/ArrowLink'
import { useMobile } from 'src/hooks/useMobile'

import SliderComponent from './components/SliderComponent'
import CashbackIcon from '../../../images/icons/Cashback'
import ArrowWhite from '../../../images/icons/ArrowWhite'
import bgCashback from '../../../images/bgCashback.png'
import bgCashbackPeople from '../../../images/bgCashbackPeople.png'
import bgCashbackMob from '../../../images/bgCashbackMob.png'
import bgCashbackPeopleMob from '../../../images/bgCashbackPeopleMob.png'

import '@splidejs/splide/dist/css/themes/splide-default.min.css'

type DecodedToken = {
  access_token: string
  exp: number
  iat: number
}

type CashbackData = {
  data: {
    balance: number
  }
}

const Cashback = () => {
  const { storageGetItem } = useStorage()
  const storageUserDecathlon = storageGetItem('user-decathlon', 'session')
  const userDataCookie = getCookie('memberAuthToken')
  const [progress, setProgress] = useState<number>()
  const [progressBRL, setProgressBRL] = useState<string>('')
  const { isMobile } = useMobile()

  useEffect(() => {
    const convertValueBRL = (value: number): string =>
      value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })

    const showDecryptedToken = () => {
      const decoded: DecodedToken = jwt_decode(userDataCookie)

      return decoded?.access_token
    }

    const getCashbackBalance = async () => {
      const { data }: CashbackData = await axios.post(
        '/api/getCashbackBalance',
        { accesstoken: showDecryptedToken() },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      )

      if (!data) {
        return {
          message: 'Não foi possivel recuperar o valor de cashback.',
        }
      }

      setProgress(data.balance)
      setProgressBRL(convertValueBRL(data.balance ?? 0))

      return data
    }

    if (userDataCookie !== null) {
      getCashbackBalance()
    }
  }, [progress, storageGetItem, storageUserDecathlon, userDataCookie])

  return (
    <div className="flex flex-wrap items-center gap-x-4 w-[full] sm:w-[700px] mt-[40px] ">
      <div className="w-full flex justify-between items-center mb-[15px]">
        <span className="font-inter text-[16px]  text-[#101010] text-base leading-5 ">
          Clube Decathlon
        </span>

        <Link
          to="https://www.decathlon.com.br/clube"
          className="flex items-center"
        >
          <span className="mr-[4px] font-inter text-[12px] leading-5 text-left text-[#3E4751]">
            Saiba mais sobre o clube
          </span>
          <ArrowLink />
        </Link>
      </div>

      <div className="block h-full sm:h-[441px] sm:flex ">
        <div
          className="relative w-full sm:w-[288px] bg-[#0073C6]  text-[#fff] rounded-[8px] z-10 w-5/5 sm:w-2/5 pt-[32px] pb-[15px]"
          style={{
            backgroundImage: `url(${isMobile ? bgCashbackMob : bgCashback})`,
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '55% auto',
          }}
        >
          <span className="ml-[20px] font-inter text-[12px] leading-5 block">
            Saldo cashback
          </span>
          <div className="mt-[3px] flex items-center font-medium text-[32px] leading-10 block tracking-normal ml-[20px]">
            <span className="mr-[5px]">{progressBRL}</span>
            <CashbackIcon />
          </div>
          <div className="border-[1px] border-dashed border-[#319dCa] w-full mt-[25px] mb-[19px]" />

          <div className="flex items-center ml-[20px]">
            <a href="https://www.decathlon.com.br/cashback/">
              <span className="font-inter  text-[12px] font-normal leading-5 ">
                Saiba como usar seu saldo
              </span>
            </a>
            <ArrowWhite />
          </div>

          <img
            className="mt-[40px] mb-[15px] w-[292px] h-[196px] sm:w-[237px] sm:h-[216px]"
            src={isMobile ? bgCashbackPeopleMob : bgCashbackPeople}
            alt="People"
            style={{ objectFit: 'contain' }}
          />
        </div>

        <SliderComponent />
      </div>
    </div>
  )
}

export default Cashback
