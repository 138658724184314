import { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { AccountContext } from 'src/components/account/context'
import { useMobile } from 'src/hooks/useMobile'
import axios from 'axios'

const SPORTS_LIST_URL =
  'https://decathlonstore.myvtex.com/api/dataentities/SP/search?_fields=name,Sports_id'

type SportsId = {
  sport_id?: number
}

type SportsName = {
  Sports_id: string
  name: string
}

const PerfilLink = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <div
      className={`text-blue text-xs leading-3 font-normal ${
        isMobile ? 'w-max mt-[10px]' : 'w-full leading-none'
      }`}
    >
      <Link to="/account/profile">Editar perfil</Link>
    </div>
  )
}

const Identification = () => {
  const { userDecathlon } = useContext(AccountContext)
  const { isMobile } = useMobile()
  const [sportsNames, setSportsNames] = useState<SportsName[]>([])
  const [userSports, setUserSports] = useState<string[]>([])

  async function getSports() {
    try {
      const response = await axios.get<SportsName[]>(SPORTS_LIST_URL, {
        headers: {
          'rest-range': 'resources=0-100',
        },
      })

      setSportsNames(response.data)
    } catch (error) {
      setSportsNames([])
    }
  }

  function onlyFirstName(name: string | undefined) {
    return name?.trim()?.split(' ')[0] ?? name
  }

  useEffect(() => {
    const userSportsIds = userDecathlon?.getSportsPractice as
      | SportsId[]
      | undefined

    if (!sportsNames.length) {
      return
    }

    if (!userSportsIds || !userSportsIds.length) {
      return
    }

    setUserSports(
      userSportsIds.map((id) => {
        const sportname = sportsNames.find(
          (name) => parseInt(name.Sports_id, 10) === id.sport_id
        )

        return sportname?.name ?? ''
      })
    )
  }, [userDecathlon, sportsNames])

  useEffect(() => {
    getSports()
  }, [])

  return (
    <div className="flex flex-wrap items-center  gap-4 sm:gap-y-0">
      <div
        className={`font-inter font-medium ${
          isMobile ? 'text-[32px]' : 'text-2xl'
        }`}
      >
        Olá, {onlyFirstName(userDecathlon?.getUserProfile?.claims?.given_name)}
      </div>
      {isMobile && <PerfilLink isMobile />}
      <div className={`flex flex-wrap gap-2 ${isMobile ? 'w-full' : 'w-max'}`}>
        {userSports.map((sport) => {
          return sport ? (
            <div
              key={sport}
              className="flex items-center justify-center h-[40px] font-inter text-sm font-normal text-restructure-secondary px-3 rounded-round bg-neutral02"
            >
              {sport}
            </div>
          ) : null
        })}
      </div>
      {!isMobile && <PerfilLink isMobile={false} />}
    </div>
  )
}

export default Identification
