import React from 'react'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'
import LoadingSVG from 'src/components/sections/Account/Loaders/LoadingSVG'

type DeleteConfirmationProps = {
  setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  handleDelete: () => void
  isLoading: boolean
}
const DeleteConfirmation = ({
  setShowDeleteConfirmation,
  handleDelete,
  isLoading,
}: DeleteConfirmationProps) => {
  return (
    <div className="fixed top-0 left-0 bg-black bg-opacity-40 z-[10000] w-full h-full flex items-center justify-center">
      <div className="w-[360px] h-[186px] bg-white flex flex-col relative rounded-md shadow-dropCard">
        <button
          className="absolute right-4 top-2 p-4"
          onClick={() => setShowDeleteConfirmation(false)}
        >
          <CloseButtonIcon />
        </button>

        <div className="mt-9 pb-0 text-center flex-1 w-full flex justify-center">
          <p className="font-inter text-base font-normal w-[186px] h-[42px] text-sm">
            Tem certeza que deseja excluir este endereço?
          </p>
        </div>

        <div className="flex flex-col px-5 py-8 gap-3">
          <button
            onClick={handleDelete}
            className="w-full h-[48px] border border-[#FF2934] rounded-round text-restructure-error font-inter text-base font-semibold hover:bg-[#f3f3f3] active:bg-[#e8e8e9] flex justify-center items-center gap-2"
          >
            Excluir endereço{' '}
            {isLoading && (
              <LoadingSVG style={{ width: 'fit-content', margin: '0' }} />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteConfirmation
