import { useCallback, useState, useEffect } from 'react'
import useConvertedImageDomain from 'src/hooks/useConvertedImageDomain'
import type { ActiveItem } from 'src/components/ShelfLinx/ShelfBoughtTogether/types'
import type {
  Installment,
  ProductItem,
} from 'src/components/product/ProductCard/types'
import { formatterPrice } from 'src/utils/formatterPrice'

interface Item {
  installments: Installment[]
}

type ProductAvailabilityProps = {
  currentProductItem: ProductItem
  isBoughtTogether: boolean
  setCurrentSku: (item: string) => void
  handleChangedItemSKU: (_setedItem: ActiveItem) => null | void
}

type ProductsInfoProps = {
  items: any
  lowPrice: number
  currentSku: string
  productItem: any
}

type DiscountProp = 'discount' | 'no-discount'

export function useGetProductsInfo({
  items,
  lowPrice,
  currentSku,
  productItem,
}: ProductsInfoProps) {
  const [betterInstallment, setBetterInstallment] =
    useState<Installment | null>(null)

  const convertImageDomain = useConvertedImageDomain()

  function generateNewActiveItem(findAvailableItem: ProductItem) {
    const sellerObject =
      findAvailableItem.sellers.find(
        (seller: any) =>
          seller.commertialOffer.Price > 0 &&
          seller.commertialOffer.AvailableQuantity > 0
      ) ?? findAvailableItem.sellers[0]

    const newActiveItem: ActiveItem = {
      itemId: findAvailableItem.itemId,
      listedPrice: sellerObject.commertialOffer.ListPrice,
      price: sellerObject.commertialOffer.Price,
      name: findAvailableItem.name,
      quantity: sellerObject.commertialOffer.AvailableQuantity,
      sellerId: sellerObject.sellerId,
    }

    return newActiveItem
  }

  const changeImage = useCallback(
    (newImage: string, setImage: (img: any) => void) =>
      setImage(convertImageDomain(newImage)),
    [convertImageDomain]
  )

  const checkProductAvailability = useCallback(
    ({
      currentProductItem,
      isBoughtTogether,
      setCurrentSku,
      handleChangedItemSKU,
    }: ProductAvailabilityProps) => {
      if (
        !currentProductItem ||
        currentProductItem.sellers[0].commertialOffer.AvailableQuantity > 0
      ) {
        return
      }

      const findAvailableItem = items?.find((item: any) =>
        item.sellers.find(
          (seller: any) => seller.commertialOffer.AvailableQuantity > 0
        )
      )

      if (!findAvailableItem) {
        return
      }

      if (!isBoughtTogether) {
        setCurrentSku(findAvailableItem.itemId)

        return
      }

      handleChangedItemSKU(generateNewActiveItem(findAvailableItem))
    },
    [items]
  )

  const listPrice =
    items?.find((item: any) => item.itemId === currentSku)?.sellers[0]
      .commertialOffer.ListPrice ?? lowPrice

  const spotPrice =
    items?.find((item: any) => item.itemId === currentSku)?.sellers[0]
      .commertialOffer.Price ?? lowPrice

  const availability = items?.some((item: any) =>
    item.sellers[0].commertialOffer.AvailableQuantity > 0 ? -1 : 1
  )
    ? 'https://schema.org/InStock'
    : 'https://schema.org/OutOfStock'

  const inStock = availability === 'https://schema.org/InStock'

  const filterBestInstallment = useCallback((itemProduct: Item) => {
    return itemProduct.installments.reduce((acc, current) => {
      return acc.value > current.value && current.numberOfInstallments <= 10
        ? current
        : acc
    }, itemProduct.installments[0])
  }, [])

  useEffect(() => {
    if (!productItem) {
      return
    }

    const better = filterBestInstallment(productItem)
    const formattedValue = formatterPrice(better?.value ?? 0)

    setBetterInstallment({ ...better, formattedValue })
  }, [productItem, filterBestInstallment])

  const hasDiscount: DiscountProp =
    spotPrice < listPrice ? 'discount' : 'no-discount'

  return {
    changeImage,
    checkProductAvailability,
    listPrice,
    spotPrice,
    availability,
    inStock,
    hasDiscount,
    betterInstallment,
  }
}
