import { useState, useContext } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import { AccountContext } from 'src/components/account/context'
import { PlusIcon } from 'src/components/Icons/PlusIcon'
import type { IToastProps } from 'src/components/sections/Account/Routes/Profile'

import { AddressForm } from './components/addresses/AddressForm'
import AddressCard from './components/addresses/AddressCard'

import './components/addresses/styles.scss'

type AddressProps = {
  setToastProps: React.Dispatch<React.SetStateAction<IToastProps>>
}

export const Addresses = ({ setToastProps }: AddressProps) => {
  const [selectedAddress, setSelectedAddress] = useState('')
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false)

  const { updateUserDecathlon, userDecathlon } = useContext(AccountContext)
  const { isMobile } = useMobile()

  const addresses = userDecathlon?.getAddresses.sort((a, b) =>
    a.id < b.id ? 1 : a.id > b.id ? -1 : 0
  )

  function handleNewAddress() {
    setSelectedAddress('')
    setIsAddressFormOpen(true)
    updateUserDecathlon()
  }

  function checkAddress() {
    return (
      addresses?.find((address) => address.id === selectedAddress) ?? undefined
    )
  }

  return isMobile && isAddressFormOpen ? (
    <div className="mb-32">
      <div className="my-12 text-center text-2xl font-medium font-inter">
        {checkAddress() ? 'Editar endereço' : 'Adicionar endereço'}
      </div>
      <AddressForm
        setIsAddressFormOpen={setIsAddressFormOpen}
        address={checkAddress()}
        setToastProps={setToastProps}
      />
    </div>
  ) : (
    <div className={`mt-12 ${isMobile ? 'mb-32' : 'mb-14'}`}>
      {addresses?.length ? (
        <ul className="address-list">
          {addresses.map((address) => {
            return (
              <AddressCard
                setIsAddressFormOpen={setIsAddressFormOpen}
                setSelectedAddress={setSelectedAddress}
                key={address.id}
                address={address}
                setToastProps={setToastProps}
              />
            )
          })}
        </ul>
      ) : (
        <p className="font-inter text-sm font-semibold mb-10 ml-2">
          Adicione um endereço para agilizar suas compras
        </p>
      )}

      <div
        role="none"
        onClick={() => handleNewAddress()}
        className="flex items-center cursor-pointer gap-2 justify-center w-full max-w-[628px] h-8 rounded-round bg-[#F7F8F9] hover:bg-[#f0f1f3] active:bg-[#e1e4e7]"
      >
        {' '}
        <PlusIcon height={16} width={16} />
        <button className="font-inter font-semibold text-sm text-blue">
          Adicionar endereço
        </button>
      </div>
      {isAddressFormOpen && (
        <AddressForm
          setIsAddressFormOpen={setIsAddressFormOpen}
          address={checkAddress()}
          setToastProps={setToastProps}
        />
      )}
    </div>
  )
}
