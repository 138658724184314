import type { Installment } from './types'

const ProductCardInstallments = ({
  installment,
}: {
  installment: Installment | null
}) => {
  return (
    <div className="product-card__installments">
      {installment && installment.numberOfInstallments > 1 && (
        <p>
          ou <span>{`${installment.numberOfInstallments}x`}</span> de{' '}
          <span>{installment?.formattedValue}</span>
        </p>
      )}
    </div>
  )
}

export default ProductCardInstallments
