import type { RouteComponentProps } from '@reach/router'
import {
  HeaderContent,
  Wishlist as WishlistSection,
} from 'src/components/sections/Account'

import 'src/styles/pages/myaccount.scss'

function Wishlist(_: RouteComponentProps) {
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center py-4 mx-auto md:flex-row md:items-start max-w-7xl">
        <div className="flex flex-col items-center w-full md:max-w-5xl md:items-unset">
          <HeaderContent
            {...{
              content: 'LISTA DE DESEJOS',
            }}
          />
          <WishlistSection
            {...{
              classContainer: 'mx-auto md:w-11/12 shadow-md bg-white rounded',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Wishlist
