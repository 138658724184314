import type { PropsWithChildren } from 'react'

import { MenuAccountProvider } from './MenuAccountProvider'

function MenuAccountAppProvider({ children }: PropsWithChildren) {
  return <MenuAccountProvider>{children}</MenuAccountProvider>
}

export { MenuAccountAppProvider as MenuAccountProvider }
export default MenuAccountAppProvider
